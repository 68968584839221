<template>
  <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      class="operationTable"
      border
      @selection-change="handleSelectionChange">
    <el-table-column
        type="selection"
        width="55">
    </el-table-column>
    <el-table-column
        prop="phone"
        label="用户账号"
        >
    </el-table-column>
    <el-table-column
        prop="name"
        label="姓名"
        >
    </el-table-column>
    <el-table-column
        prop="role"
        label="用户角色"
        show-overflow-tooltip>
    </el-table-column>
    <el-table-column
        prop="updateTime"
        label="创建时间"
        >
    </el-table-column>
    <el-table-column
        label="操作"
        width="160"
        >
      <template slot-scope="scope" >
        <el-button  type="text" :disabled="userId==scope.row.id" @click="toPermission(scope.row)" v-auth='"subAccount:permission"'>数据权限</el-button>
        &nbsp;&nbsp;
        <el-button type="text" :disabled="userId==scope.row.id"  @click="doEdit(scope.row)" v-auth='"subAccount:edit"'>编辑</el-button>
      </template>
    </el-table-column>
  </el-table>

</template>

<script>
  import moment from 'moment'
  import axios from "axios";
  export default {
    props: ['tableData'],
    name: "operationTable",
    components: {
    },
    data() {
      return {
        userId: this.getToken("userId"),
        multipleSelection: []
      }
    },
    methods: {
      toggleSelection(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      toPermission(row){
        this.$router.push({
            path:"/permission/subAccount/permission",
            query: {
              /* 业务类型  0-快捷 1-进项，2-销项 */
              row: JSON.stringify(row),
            }
         });
      },
      doEdit(row){
        this.$router.push({
          path:"/permission/subAccount/edit",
          query: {
            id: row.id,
            // row: JSON.stringify(row),
          }
        });
      }
    },
    mounted() {


    },
  };
</script>

<style scoped>
  .operationTable{
    height: 100%;
    overflow: auto;
  }
</style>
